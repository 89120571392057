





























































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VApp, VNavigationDrawer, VAppBar, VMain, VContainer, VItem, VSpacer, VBtn, VIcon } from "vuetify/lib";
import { ProfileModule } from "@/store/profile-module";
import { menu } from "./menu";
import Cookie from "./components/Cookie.vue";

@Component({
    components: {
        VApp,
        VNavigationDrawer,
        VAppBar,
        VMain,
        VContainer,
        VItem,
        VSpacer,
        VBtn,
        VIcon,
        Cookie,
    },
})
export default class App extends Vue {
    drawer = false;
    profileModule = getModule(ProfileModule);

    get menu() {
        return menu;
    }

    get full() {
        return this.$route.meta.full;
    }

    get auth() {
        return this.profileModule.profile.auth;
    }

    get webAdmin() {
        return this.profileModule.profile.webAdmin;
    }

    get admin() {
        return this.profileModule.profile.admin;
    }

    get gdpr() {
        return this.profileModule.profile.gdpr;
    }

    get gardaVetting() {
        return this.profileModule.profile.gardaVetting;
    }

    get official() {
        return this.profileModule.profile.official;
    }

    get email() {
        return this.profileModule.profile.email;
    }

    display(menu: any) {
        if (menu.auth === true) {
            return this.auth === true;
        }
        if (menu.auth === false) {
            return this.auth === false;
        }
        if (menu.admin === true) {
            return this.admin === true;
        }
        if (menu.webAdmin === true) {
            return this.webAdmin === true;
        }
        if (menu.gdpr === true) {
            return this.gdpr === true;
        }
        if (menu.gardaVetting === true) {
            return this.gardaVetting === true;
        }
        if (menu.official === true) {
            return this.official === true || this.admin === true;
        }
        return true;
    }

    isLast(item: any) {
        return item.last && this.display(item);
    }
}
