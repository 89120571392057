import Axios from "axios";
import { router } from "../main";

const xsrfToken = { token: "" as string };
// const authToken = { token: '' as string };

export const setXsrfToken = (token: string) => {
    xsrfToken.token = token;
};

// used to transform from the default of ?clientIds[]=1,clientIds[]=2,clientIds[]=3
// to ?clientIds=1,clientIds=2,clientIds=3 in order for the aspnet core [fromquery] to bind
const paramsSerializer = (params: { [x: string]: any[] }) => {
    const keys = Object.keys(params);
    let options = "";

    keys.forEach((key) => {
        const isParamTypeObject = typeof params[key] === "object";
        const isParamTypeArray = isParamTypeObject && params[key]?.length >= 0;

        if (!isParamTypeObject && params[key] !== undefined) {
            options += `${key}=${params[key]}&`;
        }

        if (isParamTypeObject && isParamTypeArray) {
            params[key].forEach((element: any) => {
                options += `${key}=${element}&`;
            });
        }
    });

    return options ? options.slice(0, -1) : options;
};

export const axios = Axios.create({ baseURL: "/api", paramsSerializer });
axios.interceptors.request.use((c) => {
    c.headers = Object.assign(c.headers, {
        "X-XSRF-Token": xsrfToken.token ? xsrfToken.token : "",
        // 'authorization': authToken.token ? `Bearer ${authToken.token}` : ''
    });
    return c;
});

// we want to sign the user out if they 401
axios.interceptors.response.use(
    (response) => response,
    function(error) {
        if (error.response?.status === 401) {
            router.push({
                name: "logout",
                params: { returnUrl: window.location.pathname },
            });
        }
        // we want to redirect the user back to the dash if they 403
        if (error.response?.status === 403) {
            router.push("/");
        }

        // Do something with response error
        return Promise.reject(error);
    }
);

(window as any).axios = axios;
