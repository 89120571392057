import { axios } from "./axios";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import store from "../store";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "profile",
})
export class ProfileModule extends VuexModule {
    _profile: Profile = {
        name: "",
        loaded: false,
        auth: false,
        webAdmin: false,
        admin: false,
        official: false,
        gdpr: false,
        gardaVetting: false,
        email: "",
        teams: [],
    };

    get profile() {
        return this._profile;
    }

    @Mutation
    setProfile(profile: Profile) {
        this._profile = profile;
    }

    @Mutation
    clearMyProfile() {
        this._profile = { admin: false, auth: false } as Profile;
    }

    @Action
    logout() {
        return axios.post("/profile/logout");
    }

    @Action
    get() {
        // eslint-disable-next-line arrow-parens
        return axios.get<Profile>("/profile").then((res) => this.setProfile(res.data));
    }

    @Action
    verify(data: any) {
        // eslint-disable-next-line arrow-parens
        return axios.post("/profile", data, { withCredentials: true }).then((res) => this.get().then(() => res));
    }

    @Action
    setEmail(email: string) {
        // eslint-disable-next-line arrow-parens
        return axios.post("/profile/email", { email });
    }
}

export interface Profile {
    name: string;
    loaded: boolean;
    auth: boolean;
    webAdmin: boolean;
    admin: boolean;
    gdpr: boolean;
    gardaVetting: boolean;
    official: boolean;
    email: string;
    teams: number[];
}
