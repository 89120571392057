





























import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Cookie extends Vue {
    dialog = false;
    consent = false;

    created() {
        this.consent = !!localStorage.getItem("consent");
    }

    ok() {
        this.dialog = false;
        this.consent = true;
        localStorage.setItem("consent", "true");
    }
}
