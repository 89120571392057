// import "babel-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import vuetify from "./plugins/vuetify";
import "./plugins/google-maps";
import { routes } from "./router";
import store from "./store";
import VueRouter from "vue-router";
import VueAppInsights from "vue-application-insights";

import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
const profileModule = getModule(ProfileModule);
// Set rawError to true by default on all @Action decorators
import { config } from "vuex-module-decorators";
config.rawError = true;

Vue.config.productionTip = false;

Vue.use(VueRouter);
export const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode: "history",
});

Vue.use(VueGtag, { config: { id: "UA-66918599-1" } }, router);
Vue.use(VueAppInsights, { id: process.env.VUE_APP_INSIGHTS, router });

Vue.config.errorHandler = function(err, vm, info) {
    const props = {
        fullPath: vm ? vm.$router.currentRoute.fullPath : "undefined",
        info: info,
        t: "vue",
    };

    (Vue as any).appInsights.trackException({
        exception: err,
        properties: props,
    });

    throw err;
};

window.onunhandledrejection = function(e: any) {
    (Vue as any).appInsights.trackException({
        exception: e.reason,
        properties: {
            fullPath: window.location?.pathname,
            t: "unhandledrejection",
        },
    });
};

profileModule.get().then(() =>
    new Vue({
        vuetify,
        router,
        render: (h) => h(App),
        store,
    }).$mount("#app")
);
