import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps-withscopedautocomp";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBA4kHI7PKaVMkbyGh_SbaPR_MppLFfNp8",
        libraries: "places",
    },
    installComponents: true,
});
